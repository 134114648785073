import { DecimalPipe, registerLocaleData } from '@angular/common';
import {
  HttpBackend,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePl from '@angular/common/locales/pl';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  ONYX_HERE_MAPS_API_KEY,
  ONYX_TRANSLATIONS_SOURCE,
  OnyxAddressService,
  OnyxDatePipe,
  OnyxDictionariesService,
  OnyxLanguagePipe,
  OnyxPreferencesService,
  OnyxStorageService,
} from '@onyx/angular';
import * as Sentry from '@sentry/angular-ivy';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { I18N_NAMESPACES } from '../config/config';
import { environment } from '../config/environment';
import { ROUTES } from './app.routes';
import { authInterceptor } from './auth/common/interceptors/auth.interceptor';
import { VehicleIdentifierPipe } from './common/components/pipes/vehicle-identifier.pipe';
import { AddressService } from './common/services/address.service';
import { DictionariesService } from './common/services/dictionaries.service';
import { LanguageService } from './common/services/language.service';
import { PreferencesService } from './common/services/preferences.service';
import { StorageService } from './common/services/storage.service';

registerLocaleData(localeDe);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localePl);

const translationsApiUrl = new URL(environment.apiUrl);
translationsApiUrl.host = `batim.${translationsApiUrl.host}`;

export const createTranslateLoader = (http: HttpBackend) => {
  return new MultiTranslateHttpLoader(http, [
    ONYX_TRANSLATIONS_SOURCE,
    ...I18N_NAMESPACES.map((namespace) => ({
      prefix: `i18n/${namespace}/`,
      suffix: '.json',
    })),
    { prefix: `${translationsApiUrl}/dictionaries/`, suffix: ' ' },
  ]);
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(ROUTES),
    provideHttpClient(withInterceptors([authInterceptor])),

    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpBackend],
        },
      }),
    ]),

    {
      provide: LOCALE_ID,
      useFactory: (languageService: LanguageService) =>
        languageService.getLocale(),
      deps: [LanguageService],
    },

    DecimalPipe,

    OnyxDatePipe,
    OnyxLanguagePipe,

    VehicleIdentifierPipe,

    // ONYX Angular Kit
    { provide: OnyxAddressService, useClass: AddressService },
    { provide: OnyxDictionariesService, useClass: DictionariesService },
    { provide: OnyxStorageService, useClass: StorageService },
    { provide: OnyxPreferencesService, useClass: PreferencesService },
    { provide: ONYX_HERE_MAPS_API_KEY, useValue: environment.hereMapsApiKey },

    // Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => undefined,
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
